import React from 'react';
import Flags from 'country-flag-icons/react/3x2';
import { hasFlag } from 'country-flag-icons';
import PropTypes from 'prop-types';
import styles from './FlagFromPhoneNumber.module.scss';
import { getCountryCodeFromNumber } from '../../helpers';

function FlagFromPhoneNumber({ phoneNumber }) {
  const countryCode = getCountryCodeFromNumber(phoneNumber);
  if (hasFlag(countryCode)) {
    return React.createElement(Flags[countryCode], { className: styles.flags });
  }
  return <span className={styles.flags}> 🏳 </span>;
}

FlagFromPhoneNumber.propTypes = {
  phoneNumber: PropTypes.string,
};

export default FlagFromPhoneNumber;
