import React, { memo } from 'react';
import { Tag } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

function TransactionStatus({ status }) {
  const statusToText = (status) => {
    switch (status) {
      case 1:
        return (
          <Tag icon={<ClockCircleOutlined />} color="yellow">
            waiting for payment
          </Tag>
        );
      case 2:
        return (
          <Tag icon={<ClockCircleOutlined />} color="processing">
            waiting for fulfillment
          </Tag>
        );
      case 3:
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Sent
          </Tag>
        );
      case 4:
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            fulfillment failed
          </Tag>
        );
      case 5:
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            payment failed
          </Tag>
        );
      case 6:
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            voided
          </Tag>
        );
      default:
        return <Tag color="default">unknown</Tag>;
    }
  };
  return statusToText(status);
}

TransactionStatus.propTypes = {
  status: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
};

export default memo(TransactionStatus);
