import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

function LongRenderLoading({ children, disabled, ...otherProps }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timer;
    if (loading) {
      timer = setTimeout(() => {
        setLoading(false);
      }, 10);
    }

    return () => clearTimeout(timer);
  }, []);

  if (disabled) {
    return children;
  }

  if (loading) {
    return <LoadingSpinner {...otherProps} />;
  }

  return children;
}

LongRenderLoading.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.element,
};

LongRenderLoading.defaultProps = {
  children: null,
  disabled: false,
};

export default LongRenderLoading;
