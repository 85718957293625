/* eslint-disable */

import dayjs from 'dayjs';
import { store } from '../main';
import { message } from 'antd';

const flomV1Url = process.env.REACT_APP_BASE_URL_OLD;
const flomV2Url = process.env.REACT_APP_BASE_URL;

export class ServerError extends Error {
  constructor(message = '', code) {
    super(message);
    this.name = 'ServerError';
    this.code = code;
    this.time = Date.now();
  }
}

export const checkResponse = (response, additionalSuccessCodes = []) => {
  if (response?.data?.code === 1) {
    return response;
  }

  const successResponse = additionalSuccessCodes.find((code) => code === response?.data?.code);
  if (successResponse) {
    return response;
  }

  if (response?.data?.code === 4000007) {
    message.warning({
      key: 'logout',
      content: 'Your session has expired. Please sign in again.',
      duration: 10,
    });
    store.dispatch({ type: 'RESET' });

    console.warn('Code:', response?.data?.code, 'response?.data:', response?.data);
    throw new ServerError('', 4000007);
  }

  if (response?.data?.code) {
    const errorMessage = response.data.errorMessage
      ? response.data.errorMessage
      : 'Something went wrong, please try again later.';

    console.groupCollapsed(
      'API:',
      response?.request?.path ||
        response?.request?.responseURL?.replace(flomV1Url, '')?.replace(flomV2Url, '')
    );
    console.log('Time:', dayjs().format('DD.MM.YYYY HH:mm:ss'));
    console.log('Error code:', response.data.code);
    console.log('Error message:', errorMessage);
    console.log(' ');
    console.groupEnd();

    throw new ServerError(errorMessage, response.data.code);
  }

  // unknown error
  console.groupCollapsed(
    'API:',
    response?.request?.path ||
      response?.request?.responseURL?.replace(flomV1Url, '')?.replace(flomV2Url, '')
  );
  console.log('Time:', dayjs().format('DD.MM.YYYY HH:mm:ss'));
  console.log('Unknown error:');
  console.log(' ');
  console.groupEnd();

  throw new ServerError('Something went wrong, please try again later.', 9999999);
};

export const checkResponseFlom = (response, additionalSuccessCodes = []) => {
  if (checkResponse(response, additionalSuccessCodes)) {
    return { code: response.data.code, time: response.data.time, ...response.data.data };
  }
};

export const paramsFromQuery = (queryKeys = {}) => {
  const parameterKeys = Object.keys(queryKeys);
  const searchParameters = new URLSearchParams();

  if (Array.isArray(parameterKeys)) {
    parameterKeys.map((parameterKey) => {
      searchParameters.append(parameterKey, queryKeys[parameterKey]);
      return null;
    });

    return searchParameters;
  }
};

export const formDataFromObject = (data = {}) => {
  const keys = Object.keys(data);
  const formData = new FormData();

  keys.map((key) => {
    if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
    return null;
  });
  return formData;
};
