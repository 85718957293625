const roleMinMax = (role, minRole, maxRole) => {
  if (minRole <= role && role <= maxRole) {
    return true;
  }
  return false;
};

const roleExclude = (role, exclude = []) => {
  if (exclude.length > 0) {
    const isExcluded = exclude.find((exc) => role === exc);
    return isExcluded ? true : false;
  }
  return false;
};

const roleInclude = (role, include = []) => {
  if (include.length > 0) {
    const isIncluded = include.find((inc) => inc === role);
    return isIncluded ? true : false;
  }
  return false;
};

export const checkAll = (role, minRole, maxRole, include, exclude) => {
  const isExcluded = roleExclude(role, exclude);

  if (!isExcluded) {
    if (roleMinMax(role, minRole, maxRole) || roleInclude(role, include)) {
      return true;
    }
  }
  return false;
};
