import { mainRoutes, generateAbsoluteRoutes } from '../main/routes';

export const supportRoutesRelative = {
  404: '/*',
  TRANSACTIONS: '/transactions',
  TRANSACTIONS_DETAILS: '/transactions/:id',
  BANNED_IPS: '/banned-ips',
  BANNED_PHONES: '/banned-phones',
  TRANSACTION_LIMITS: '/transaction-limits',
  SUPPORT_TICKETS: '/support-tickets',
  SUPPORT_TICKETS_DETAILS: '/support-tickets/:id',
  PUSH_NOTIFICATIONS: '/push-notifications',
  SETTINGS: '/settings',
  CONVERSION_RATES: '/conversion-rates',
  PROMOTIONS: '/promotions',
  PROMOTION_SETTINGS: '/promotions/settings',
  PROMOTION_CREATE: '/promotions/create-new',
};

export const supportRoutes = generateAbsoluteRoutes(supportRoutesRelative, mainRoutes.SUPPORT);

export const supportLabels = {
  [supportRoutes.TRANSACTIONS]: 'Transactions v2',
  [supportRoutes.BANNED_IPS]: 'Banned IPs',
  [supportRoutes.BANNED_PHONES]: 'Banned phones',
  [supportRoutes.TRANSACTION_LIMITS]: 'Transaction limits',
  [supportRoutes.SUPPORT_TICKETS]: 'Support tickets v2',
  [supportRoutes.PUSH_NOTIFICATIONS]: 'Send notifications',
  [supportRoutes.SETTINGS]: 'Settings',
  [supportRoutes.CONVERSION_RATES]: 'Conversion rates',
  [supportRoutes.PROMOTIONS]: 'Promotions',
  [supportRoutes.PROMOTION_SETTINGS]: 'Settings',
  [supportRoutes.PROMOTION_CREATE]: 'Create',
};

export const breadcrumbLabels = {
  transactions: ['flom v2', supportLabels[supportRoutes.TRANSACTIONS]],
  bannedIps: ['flom v2', supportLabels[supportRoutes.BANNED_IPS]],
  bannedPhones: ['flom v2', supportLabels[supportRoutes.BANNED_PHONES]],
  transactionLimits: ['flom v2', supportLabels[supportRoutes.TRANSACTION_LIMITS]],
  supportTickets: ['flom v2', supportLabels[supportRoutes.SUPPORT_TICKETS]],
  pushNotifications: ['flom v2', supportLabels[supportRoutes.PUSH_NOTIFICATIONS]],
  settings: ['flom v2', supportLabels[supportRoutes.SETTINGS]],
  conversionRates: ['flom v2', supportLabels[supportRoutes.CONVERSION_RATES]],
  promotions: ['flom v2', supportLabels[supportRoutes.PROMOTIONS]],
  promotionSettings: [
    'flom v2',
    supportLabels[supportRoutes.PROMOTIONS],
    supportLabels[supportRoutes.PROMOTION_SETTINGS],
  ],
  promotionCreate: [
    'flom v2',
    supportLabels[supportRoutes.PROMOTIONS],
    supportLabels[supportRoutes.PROMOTION_CREATE],
  ],
};

export const breadcrumbLinks = {
  transactions: [undefined, supportRoutes.TRANSACTIONS],
  bannedIps: [undefined, supportRoutes.BANNED_IPS],
  bannedPhones: [undefined, supportRoutes.BANNED_PHONES],
  transactionLimits: [undefined, supportRoutes.TRANSACTION_LIMITS],
  supportTickets: [undefined, supportRoutes.SUPPORT_TICKETS],
  pushNotifications: [undefined, supportRoutes.PUSH_NOTIFICATIONS],
  settings: [undefined, supportRoutes.SETTINGS],
  conversionRates: [undefined, supportRoutes.CONVERSION_RATES],
  promotions: [undefined, supportRoutes.PROMOTIONS],
  promotionSettings: [undefined, supportRoutes.PROMOTIONS, supportRoutes.PROMOTION_SETTINGS],
  promotionCreate: [undefined, supportRoutes.PROMOTIONS, supportRoutes.PROMOTION_CREATE],
};

export const supportSubMenu = {
  promotions: '/promotions',
};
