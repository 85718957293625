import React from 'react';
import { Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMobileCheck } from 'core/hooks';
import { LoadingFlom } from 'features/components';
import TransactionListWeb from './TransactionListWeb';
import TransactionListMobile from './TransactionListMobile';

function TransactionList({ isLoading, isError, items, currentPage, onPageChange, ...otherProps }) {
  const isMobile = useMobileCheck();
  const navigate = useNavigate();
  const location = useLocation();

  if (isLoading) {
    return <LoadingFlom />;
  }

  if (isError) {
    return <Typography.Text>Something went wrong, please try again later.</Typography.Text>;
  }

  if (items.length === 0) {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
      return null;
    }
    return <Typography.Text type="danger">No results were found.</Typography.Text>;
  }

  const onClickHandler = (id) => {
    navigate(`${location.pathname}/${id}`, { state: { prevSearch: location.search, id } });
  };

  return isMobile ? (
    <TransactionListMobile items={items} onClick={onClickHandler} {...otherProps} />
  ) : (
    <TransactionListWeb items={items} onClick={onClickHandler} {...otherProps} />
  );
}

TransactionList.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  items: PropTypes.array,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
};

TransactionList.defaultProps = {
  isLoading: false,
  isError: false,
  items: [],
  onPageChange: () => {},
};

export default TransactionList;
