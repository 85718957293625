import React, { useEffect } from 'react';
import { Row, Col, Button, Form } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isValidPhoneNumber } from 'features/helpers';
import {
  ReCaptchaFormItem,
  UsernameInput,
  PasswordNewInput,
  PasswordConfirmInput,
  EmailInput,
  FirstNameInput,
  LastNameInput,
  PhoneNumberInput,
  AddressInput,
} from 'features/components';

function SignUpForm({ onFinish, error, resetError, isLoading, mutationError }) {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.form) {
      form.setFieldsValue(location.state.form);
      navigate({ pathname: location.pathname }, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      form.validateFields();
    }
  }, [error]);

  const onFinishHandler = () => {
    onFinish({ form: form.getFieldsValue() });
    form.setFieldsValue({ reCaptcha: undefined });
  };

  // const onResetHandler = () => {
  //   form.resetFields();
  // };

  return (
    <Form form={form} layout="vertical" name="signUp" onFinish={onFinishHandler}>
      <Form.Item
        label="Username:"
        name="username"
        rules={[
          { required: true, message: '*username is required field' },
          {
            validator: (_, value) => {
              if (value) {
                const regex = /^[\w\-~]*$/;
                if (!regex.test(value)) {
                  return Promise.reject(
                    new Error(
                      'Username can contain uppercase, lowercase, numbers and following special characters -_~'
                    )
                  );
                }
              }
              return Promise.resolve();
            },
          },
          {
            validator: () => {
              if (error.username) {
                return Promise.reject(
                  new Error('This username is already taken, please choose another username.')
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <UsernameInput autoFocus onChange={resetError} />
      </Form.Item>

      <Form.Item
        label="Password:"
        name="password"
        validateTrigger="onSubmit"
        rules={[
          { required: true, message: '*password is required field' },
          {
            validator: (_, value) => {
              //password confirmation
              if (!value) {
                return Promise.resolve();
              }
              if (value === form.getFieldValue('confirmPassword')) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The password confirmation does not match.'));
            },
          },
          {
            validator: (_, value) => {
              // password length
              if (value) {
                if (value.length < 8) {
                  return Promise.reject(
                    new Error('Passwords must be at least 8 characters long. ')
                  );
                }
              }
              return Promise.resolve();
            },
          },
          {
            validator: (_, value) => {
              // uppercase
              if (value) {
                const regex = /[A-Z]/gm;
                if (!regex.test(value)) {
                  return Promise.reject(
                    new Error('The password must contain uppercase characters (A-Z).')
                  );
                }
              }
              return Promise.resolve();
            },
          },
          {
            validator: (_, value) => {
              // lowercase
              if (value) {
                const regex = /[a-z]/gm;
                if (!regex.test(value)) {
                  return Promise.reject(
                    new Error('The password must contain lowercase characters (a-z).')
                  );
                }
              }
              return Promise.resolve();
            },
          },
          {
            validator: (_, value) => {
              // digits
              if (value) {
                const regex = /\d/gm;
                if (!regex.test(value)) {
                  return Promise.reject(new Error('The password must contain digits (0-9).'));
                }
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <PasswordNewInput />
      </Form.Item>

      <Form.Item
        label="Confirm password:"
        name="confirmPassword"
        validateTrigger="onSubmit"
        rules={[
          { required: true, message: '*confirm password is required field' },
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve();
              }
              if (value === form.getFieldValue('password')) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(' '));
            },
          },
        ]}
      >
        <PasswordConfirmInput />
      </Form.Item>

      <Form.Item
        label="Email:"
        name="email"
        validateTrigger="onSubmit"
        rules={[
          { required: true, message: '*email is required field' },
          {
            validator: (_, value) => {
              const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;

              if (!value) {
                return Promise.resolve();
              }
              if (regex.test(String(value).toLowerCase())) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(`The format of the email address isn't correct.`));
            },
          },
          {
            validator: () => {
              if (error.email) {
                return Promise.reject(
                  new Error('This email is already taken, please choose another email.')
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <EmailInput onChange={resetError} />
      </Form.Item>

      <Form.Item
        label="First name:"
        name="firstName"
        rules={[{ required: true, message: '*first name is required field' }]}
      >
        <FirstNameInput />
      </Form.Item>

      <Form.Item
        label="Last name:"
        name="lastName"
        rules={[{ required: true, message: '*last name is required field' }]}
      >
        <LastNameInput />
      </Form.Item>

      <Form.Item
        label="Phone number:"
        name="phoneNumber"
        validateTrigger="onSubmit"
        rules={[
          { required: true, message: '*phone number is required field' },
          {
            validator: (_, value) => {
              if (value) {
                if (isValidPhoneNumber(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`The phone number is incorrect.`));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <PhoneNumberInput />
      </Form.Item>

      <Form.Item
        label="Address:"
        name="address"
        rules={[{ required: true, message: '*address is required field' }]}
      >
        <AddressInput />
      </Form.Item>

      <ReCaptchaFormItem />

      <Row align="middle" justify="center">
        <Col>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Sign up
            </Button>
          </Form.Item>
        </Col>
        {/* <Col style={{ width: '3rem' }} /> */}
        {/* <Col>
          <Form.Item>
            <Button type="secondary" onClick={onResetHandler}>
              Reset
            </Button>
          </Form.Item>
        </Col> */}
      </Row>
    </Form>
  );
}

SignUpForm.propTypes = {
  onFinish: PropTypes.func,
  error: PropTypes.object,
  resetError: PropTypes.func,
  isLoading: PropTypes.bool,
};

SignUpForm.defaultProps = {
  onFinish: () => {},
  error: {},
  resetError: () => {},
  isLoading: false,
};

export default SignUpForm;
