import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

function TransactionIdFormItem({ className }) {
  return (
    <Form.Item label="Transaction ID" name="transactionId" className={className}>
      <Input autoComplete="transactionId" autoCapitalize="off" />
    </Form.Item>
  );
}

TransactionIdFormItem.propTypes = {
  className: PropTypes.string,
};

TransactionIdFormItem.defaultProps = {
  className: '',
};

export default TransactionIdFormItem;
