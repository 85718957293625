import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { ErrorBoundary } from '../../errorBoundaries';
import {
  breadcrumbLabels,
  supportRoutes,
  breadcrumbLinks,
  supportSubMenu,
  supportRoutesRelative,
} from '../routes';
import { mainRoutes } from '../../main';
import { SetNavigation } from '../../layout';
import { LoadingFlom } from '../../components';
import { CheckRole } from 'features/hoc';
import { supportRoleRules } from '../hooks';

// lazyImports
const Transactions = lazy(() => import('../transactions/fragments/transactions/Transactions'));
const TransactionDetails = lazy(() =>
  import('../transactions/fragments/transactionDetails/TransactionDetails')
);
const BannedIps = lazy(() => import('../bannedIps/fragments/bannedIps/BannedIps'));
const BannedPhoneNumbers = lazy(() =>
  import('../bannedPhones/fragments/bannedPhoneNumbers/BannedPhoneNumbers')
);
const PageNotFound = lazy(() => import('../../error-pages/fragments/pageNotFound/PageNotFound'));
const TransactionLimits = lazy(() =>
  import('../transaction_limits/fragments/transactionLimits/TransactionLimits')
);
const SupportTickets = lazy(() =>
  import('../supportTickets/fragments/supportTickets/SupportTickets')
);
const SupportTicketDetails = lazy(() =>
  import('../supportTickets/fragments/supportTicketsDetails/SupportTicketsDetails')
);
const PushNotifications = lazy(() =>
  import('../pushNotifications/fragment/pushNotifications/PushNotifications')
);
const Settings = lazy(() => import('../settings/fragments/settings/Settings'));
const ConversionRates = lazy(() =>
  import('../conversionRates/fragments/conversionRates/ConversionRates')
);
const Promotions = lazy(() => import('../promotions/fragments/promotions/Promotions'));
const PromotionSettings = lazy(() =>
  import('../promotions/fragments/promotionSettings/PromotionSettings')
);
const CreatePromotion = lazy(() => import('../promotions/fragments/createNew/CreateNew'));

function Support() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingFlom />}>
        <Routes>
          <Route
            path={supportRoutesRelative.TRANSACTIONS_DETAILS}
            element={
              <CheckRole {...supportRoleRules.other}>
                <TransactionDetails />
              </CheckRole>
            }
          />

          <Route
            path={supportRoutesRelative.TRANSACTIONS}
            element={
              <CheckRole {...supportRoleRules.other}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.transactions}
                  breadcrumbsLabels={breadcrumbLabels.transactions}
                  siderOpenedKey={mainRoutes.SUPPORT}
                  siderSelectedKey={supportRoutes.TRANSACTIONS}
                >
                  <Transactions />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportRoutesRelative.BANNED_IPS}
            element={
              <CheckRole {...supportRoleRules.other}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.bannedIps}
                  breadcrumbsLabels={breadcrumbLabels.bannedIps}
                  siderOpenedKey={mainRoutes.SUPPORT}
                  siderSelectedKey={supportRoutes.BANNED_IPS}
                >
                  <BannedIps />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportRoutesRelative.BANNED_PHONES}
            element={
              <CheckRole {...supportRoleRules.other}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.bannedPhones}
                  breadcrumbsLabels={breadcrumbLabels.bannedPhones}
                  siderOpenedKey={mainRoutes.SUPPORT}
                  siderSelectedKey={supportRoutes.BANNED_PHONES}
                >
                  <BannedPhoneNumbers />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportRoutesRelative.TRANSACTION_LIMITS}
            element={
              <CheckRole {...supportRoleRules.other}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.transactionLimits}
                  breadcrumbsLabels={breadcrumbLabels.transactionLimits}
                  siderOpenedKey={mainRoutes.SUPPORT}
                  siderSelectedKey={supportRoutes.TRANSACTION_LIMITS}
                >
                  <TransactionLimits />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportRoutesRelative.SUPPORT_TICKETS_DETAILS}
            element={
              <CheckRole {...supportRoleRules.supportTickets}>
                <SupportTicketDetails />
              </CheckRole>
            }
          />
          <Route
            path={supportRoutesRelative.SUPPORT_TICKETS}
            element={
              <CheckRole {...supportRoleRules.supportTickets}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.supportTickets}
                  breadcrumbsLabels={breadcrumbLabels.supportTickets}
                  siderOpenedKey={mainRoutes.SUPPORT}
                  siderSelectedKey={supportRoutes.SUPPORT_TICKETS}
                >
                  <SupportTickets />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportRoutesRelative.PUSH_NOTIFICATIONS}
            element={
              <CheckRole {...supportRoleRules.other}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.pushNotifications}
                  breadcrumbsLabels={breadcrumbLabels.pushNotifications}
                  siderOpenedKey={mainRoutes.SUPPORT}
                  siderSelectedKey={supportRoutes.PUSH_NOTIFICATIONS}
                >
                  <PushNotifications />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportRoutesRelative.SETTINGS}
            element={
              <CheckRole {...supportRoleRules.other}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.settings}
                  breadcrumbsLabels={breadcrumbLabels.settings}
                  siderOpenedKey={mainRoutes.SUPPORT}
                  siderSelectedKey={supportRoutes.SETTINGS}
                >
                  <Settings />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportRoutesRelative.CONVERSION_RATES}
            element={
              <CheckRole {...supportRoleRules.other}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.conversionRates}
                  breadcrumbsLabels={breadcrumbLabels.conversionRates}
                  siderOpenedKey={mainRoutes.SUPPORT}
                  siderSelectedKey={supportRoutes.CONVERSION_RATES}
                >
                  <ConversionRates />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportRoutesRelative.PROMOTION_SETTINGS}
            element={
              <CheckRole {...supportRoleRules.other}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.promotionSettings}
                  breadcrumbsLabels={breadcrumbLabels.promotionSettings}
                  siderOpenedKeys={[mainRoutes.SUPPORT, supportSubMenu.promotions]}
                  siderSelectedKey={supportRoutes.PROMOTION_SETTINGS}
                >
                  <PromotionSettings />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportRoutesRelative.PROMOTION_CREATE}
            element={
              <CheckRole {...supportRoleRules.other}>
                <CreatePromotion />
              </CheckRole>
            }
          />

          <Route
            path={supportRoutesRelative.PROMOTIONS}
            element={
              <CheckRole {...supportRoleRules.other}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.promotions}
                  breadcrumbsLabels={breadcrumbLabels.promotions}
                  siderOpenedKeys={[mainRoutes.SUPPORT, supportSubMenu.promotions]}
                  siderSelectedKey={supportRoutes.PROMOTIONS}
                >
                  <Promotions />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route path="/" element={<Navigate to={supportRoutes.SUPPORT_TICKETS} />} />

          <Route path={supportRoutesRelative[404]} element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Support;
