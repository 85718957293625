import React from 'react';
import { Typography } from 'antd';
import styles from './PageNotFound.module.scss';
import { SetNavigation } from '../../../layout';

function PageNotFound() {
  return (
    <SetNavigation>
      <div className={styles.mainContainer}>
        <Typography.Text className={styles.title}>404</Typography.Text>
        <Typography.Text className={styles.text}>
          Sorry, we couldn't find that page...
        </Typography.Text>
      </div>
    </SetNavigation>
  );
}

export default PageNotFound;
