import React, { useEffect } from 'react';
import { Row, Col, Input, Button, Form, Typography } from 'antd';
import PropTypes from 'prop-types';

function EmailCodeForm({ onFinish, isLoading, email, onBack, error, resetError }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(error).length) {
      form.validateFields();
    }
  }, [error]);

  const onFinishHandler = () => {
    onFinish(form.getFieldValue('emailCode'));
  };

  return (
    <Form form={form} layout="vertical" name="signUp" onFinish={onFinishHandler}>
      <Form.Item
        label={
          <Typography.Text>
            Email code: {email && <Typography.Text type="secondary">({email})</Typography.Text>}
          </Typography.Text>
        }
        name="emailCode"
        rules={[
          { required: true, message: 'Please enter the code from the email.' },
          {
            validator: () => {
              if (error.emailCode) {
                return Promise.reject(new Error('Invalid or expired code.'));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input autoComplete="emailCode" name="emailCode" onChange={resetError} />
      </Form.Item>

      <Form.Item>
        <Row align="middle" justify="center">
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
          </Col>
          <Col style={{ width: '3rem' }} />
          {/* <Col>
            <Form.Item>
              <Button onClick={onBack}>Back</Button>
            </Form.Item>
          </Col> */}
        </Row>
      </Form.Item>
    </Form>
  );
}

EmailCodeForm.propTypes = {
  onFinish: PropTypes.func,
  isLoading: PropTypes.bool,
  email: PropTypes.string,
  error: PropTypes.object,
  resetError: PropTypes.func,
  onBack: PropTypes.func,
};

EmailCodeForm.defaultProps = {
  onFinish: () => {},
  error: {},
  resetError: () => {},
  isLoading: false,
  onBack: () => {},
};

export default EmailCodeForm;
