import React from 'react';
import { Typography, Switch, notification, message } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { editContent } from 'features/discover/api/productsApi';

function UpdateContent({ product = {} }) {
  const queryClient = useQueryClient();

  const { appropriateForKids, _id, type } = product;

  const editContentMutation = useMutation(
    (changedContent) => editContent(_id, changedContent, type),
    {
      onMutate: () => {
        message.loading({
          content: 'Updating content.',
          key: 'editContent',
          duration: 100,
        });
      },
      onSuccess: (res) => {
        queryClient.setQueryData(['Product', { id: _id }], res);

        message.success({
          content: 'Content update complete.',
          key: 'editContent',
          duration: 3,
        });
      },
      onError: (error) => {
        message.destroy('editContent');

        notification.error({
          message: 'Error: ' + error?.code,
          description: error?.message || 'Content update failed!',
          key: 'productFeatured',
          duration: 20,
        });
      },
    }
  );

  const onAppropriateForKidsChangeHandler = (value) => {
    editContentMutation.mutate({ appropriateForKids: value });
  };

  return (
    <>
      <div className="col-span-2 h-0.5 bg-gray-200" />
      <div className="col-span-2">
        <Typography.Text strong className="justify-self-end items-center">
          Edit Content:
        </Typography.Text>
      </div>

      <Typography.Text className="justify-self-end items-center" strong>
        Appropriate for kids:
      </Typography.Text>
      <div>
        <Switch
          {...(appropriateForKids && { checked: true })}
          onChange={onAppropriateForKidsChangeHandler}
          loading={editContentMutation.isLoading}
        />
      </div>
    </>
  );
}

UpdateContent.propTypes = {
  product: PropTypes.object,
};

export default UpdateContent;
