import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import styles from './LoadingSpinner.module.scss';

function LoadingSpinner({ size }) {
  return <Spin size={size} className={styles.spinner} />;
}

LoadingSpinner.propTypes = {
  size: PropTypes.oneOf(['small', 'default', 'large']),
};

LoadingSpinner.defaultProps = {
  size: 'large',
};

export default LoadingSpinner;
