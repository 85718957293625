import { countries as unsortedCountries } from 'countries-list';

const sortCountries = (unsortedCounties) => {
  const keys = Object.keys(unsortedCounties);

  const sort = keys.sort((a, b) => {
    return unsortedCountries[a].name.localeCompare(unsortedCountries[b].name);
  });

  return sort.map((key) => ({
    name: unsortedCounties[key].name,
    phone: '+' + unsortedCounties[key].phone,
    code: key,
    currency: unsortedCounties[key].currency,
  }));
};

export const countries = sortCountries(unsortedCountries);
