import React, { memo } from 'react';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import styles from './TransactionListWeb.module.scss';
import { PhoneNumberFilterRedirect } from 'features/components';
import TransactionStatus from '../transactionStatus/TransactionStatus';
import TransactionType from '../transactionType/TransactionType';

function TransactionListWeb({ items, onClick }) {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th scope="col">
            <Typography.Title level={5}>Date:</Typography.Title>
          </th>
          <th scope="col">
            <Typography.Title level={5}>Time:</Typography.Title>
          </th>
          <th scope="col">
            <Typography.Title level={5}>Sender:</Typography.Title>
          </th>
          <th scope="col">
            <Typography.Title level={5}>Receiver:</Typography.Title>
          </th>
          <th scope="col">
            <Typography.Title level={5}>Transaction ID:</Typography.Title>
          </th>
          <th scope="col">
            <Typography.Title level={5}>Type:</Typography.Title>
          </th>
          <th scope="col">
            <Typography.Title level={5}>Status:</Typography.Title>
          </th>
          <th scope="col">
            <Typography.Title level={5}>Amount:</Typography.Title>
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr
            key={item.id}
            onClick={() => {
              onClick(item.id);
            }}
          >
            <td className={styles.center}>
              <Typography.Text>{dayjs(item.created).format('DD/MM/YY')}</Typography.Text>
            </td>
            <td className={styles.center}>
              <Typography.Text>{dayjs(item.created).format('H:mm:ss')}</Typography.Text>
            </td>
            <td className={styles.left}>
              <PhoneNumberFilterRedirect phoneNumber={item.senderPhoneNumber} />
            </td>
            <td className={styles.left}>
              <PhoneNumberFilterRedirect phoneNumber={item.receiverPhoneNumber} />
            </td>
            <td className={styles.left}>
              <Typography.Text>{item.id}</Typography.Text>
            </td>
            <td className={styles.center}>
              <TransactionType type={item.transferType} />
            </td>
            <td className={styles.left}>
              <div>
                <TransactionStatus status={item.status} />
              </div>
            </td>
            <td className={styles.right}>
              {item.amount && (
                <>
                  <Typography.Text>{item.amount}</Typography.Text>
                  <Typography.Text> USD</Typography.Text>
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

TransactionListWeb.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  items: PropTypes.array,
  onClick: PropTypes.func,
};

TransactionListWeb.defaultProps = {
  isLoading: false,
  isError: false,
  items: [],
  onClick: () => {},
};

export default memo(TransactionListWeb);
