import React, { memo } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

function BlockStatus({ status }) {
  const blockedStatus = (status) => {
    switch (status) {
      case 'active':
        return <Tag color="green">Active</Tag>;

      case 'blocked':
        return <Tag color="red">Blocked</Tag>;

      default:
        return <Tag color="yellow">Unknown</Tag>;
    }
  };

  return blockedStatus(status);
}

BlockStatus.propTypes = {
  status: PropTypes.oneOf(['active', 'blocked']).isRequired,
};

export default memo(BlockStatus);
