import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router';
import { ErrorBoundary } from 'features/errorBoundaries';
import { LoadingFlom } from 'features/components';
import { authRoutesRelative } from '../routes';
import { SetNavigation } from 'features/layout';

// lazyImports
const PageNotFound = lazy(() => import('features/error-pages/fragments/pageNotFound/PageNotFound'));
const VerificationByUrl = lazy(() =>
  import('../sing-up/fragments/verificationByUrl/VerificationByUrl')
);
const ResetPassword = lazy(() => import('../reset-password/fragments/resetPassword/ResetPassword'));

function Authorization() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingFlom />}>
        <Routes>
          <Route
            path={authRoutesRelative.VERIFICATION_BY_URL}
            element={
              <SetNavigation>
                <VerificationByUrl />
              </SetNavigation>
            }
          />

          <Route
            path={authRoutesRelative.RESET_PASSWORD}
            element={
              <SetNavigation>
                <ResetPassword />
              </SetNavigation>
            }
          />

          <Route path={authRoutesRelative[404]} element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Authorization;
