import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router';
import { ErrorBoundary } from 'features/errorBoundaries';
import { LoadingFlom } from 'features/components';
import { authRoutesRelative, authRoutes } from '../routes';

// lazyImports
const Login = lazy(() => import('../sign-in/fragments/Login'));
const SignUp = lazy(() => import('../sing-up/fragments/signUp/SignUp'));
const EmailCodeConfirmation = lazy(() =>
  import('../sing-up/fragments/emailCodeConfirmation/EmailCodeConfirmation')
);
const VerificationByUrl = lazy(() =>
  import('../sing-up/fragments/verificationByUrl/VerificationByUrl')
);
const AuthResetPassword = lazy(() =>
  import('../reset-password/fragments/resetPassword/AuthResetPassword')
);
const AuthResetPasswordRequest = lazy(() =>
  import('../reset-password/fragments/resetPasswordRequest/AuthResetPasswordRequest')
);

function Authorization() {
  const location = useLocation();

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingFlom />}>
        <Routes>
          <Route path={authRoutesRelative.SIGN_IN} element={<Login />} />

          <Route
            path={authRoutesRelative.EMAIL_CONFIRMATION_CODE}
            element={<EmailCodeConfirmation />}
          />

          <Route path={authRoutesRelative.VERIFICATION_BY_URL} element={<VerificationByUrl />} />

          <Route path={authRoutesRelative.RESET_PASSWORD} element={<AuthResetPassword />} />

          <Route
            path={authRoutesRelative.RESET_PASSWORD_EMAIL}
            element={<AuthResetPasswordRequest />}
          />

          <Route path={authRoutesRelative.SIGN_UP} element={<SignUp />}></Route>

          <Route
            path="*"
            element={
              <Navigate
                to={authRoutes.SIGN_IN}
                state={{ redirectAfterLogin: location.state?.redirectAfterLogin }}
              />
            }
          />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Authorization;
