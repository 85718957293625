import React, { useState } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import ReCaptcha from './ReCaptcha';

function ReCaptchaFormItem({ onChange, ...otherProps }) {
  const [error, setError] = useState(false);

  const onChangeHandler = (value) => {
    onChange(value);
    setError(false);
  };

  const onErrorHandler = () => {
    setError(true);
    onChange(undefined);
  };

  return (
    <Form.Item
      name="reCaptcha"
      validateTrigger="onSubmit"
      rules={[
        {
          validator: (_, value) => {
            if (error) {
              return Promise.reject(new Error('Captcha validation failed, please try again.'));
            }
            if (value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('*captcha is required'));
          },
        },
      ]}
    >
      <ReCaptcha {...otherProps} onChange={onChangeHandler} onErrored={onErrorHandler} />
    </Form.Item>
  );
}

ReCaptchaFormItem.propTypes = {
  onChange: PropTypes.func,
};

ReCaptchaFormItem.defaultProps = {
  onChange: () => {},
};

export default ReCaptchaFormItem;
