import React, { useState, useEffect } from 'react';
import { Pagination as AntdPagination } from 'antd';
import PropTypes from 'prop-types';
import styles from './Pagination.module.scss';

function Pagination({ totalItems: totalItms, itemsPerPage, onPageChange, currentPage, visible }) {
  const [totalItems, setTotalItems] = useState(totalItms);

  useEffect(() => {
    if (totalItms !== undefined) {
      setTotalItems(totalItms);
    } else {
      setTotalItems(0);
    }
  }, [totalItms]);

  useEffect(() => {
    document.getElementById('main').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [currentPage]);

  if (!visible) {
    return null;
  }

  return (
    <AntdPagination
      className={styles.pagination}
      total={totalItems}
      pageSize={itemsPerPage}
      onChange={onPageChange}
      current={currentPage}
      showSizeChanger={false}
      showLessItems={true}
      hideOnSinglePage={true}
    />
  );
}

Pagination.propTypes = {
  totalItems: PropTypes.number,
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  visible: PropTypes.bool,
};

Pagination.defaultProps = {
  visible: true,
  itemsPerPage: 10,
  onPageChange: () => {},
};

export default Pagination;
