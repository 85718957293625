import React, { useEffect } from 'react';
import { Form, Button } from 'antd';
import PropTypes from 'prop-types';
import { useParametersFromLocationSearch } from 'core/hooks';
import styles from './TransactionsFilter.module.scss';
import './antdOverride.scss';
import TransactionStatusFormItem from '../filterItems/TransactionStatusFormItem';
import PhoneNumberFormItem from '../filterItems/PhoneNumberFormItem';
import TransactionIdFormItem from '../filterItems/TransactionIdFormItem';

function TransactionsFilter({ onFilterChangeHandler, isLoading }) {
  const [form] = Form.useForm();
  const searchParams = useParametersFromLocationSearch();

  useEffect(() => {
    if (searchParams) {
      form.resetFields();
      form.setFieldsValue(searchParams);
    }
  }, [searchParams, form]);

  const onSubmitHandler = () => {
    const filter = form.getFieldsValue();
    const filterKeys = Object.keys(filter);
    let filteredFilter = {};
    filterKeys.map((filterKey) => {
      if (filter[filterKey]) {
        filteredFilter[filterKey] = filter[filterKey];
      }
      return null;
    });

    onFilterChangeHandler(filteredFilter);
  };

  const onClearHandler = () => {
    form.resetFields();
    onFilterChangeHandler();
  };

  return (
    <div className="override-antd">
      <Form name="filter" form={form} onFinish={onSubmitHandler} className={styles.container}>
        <TransactionStatusFormItem />

        <PhoneNumberFormItem />

        <TransactionIdFormItem className={styles.title} />

        <div className={styles.buttonContainer}>
          <Form.Item className={styles.filterButton}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Filter
            </Button>
          </Form.Item>

          <Form.Item className={styles.clearButton}>
            <Button type="dashed" danger onClick={onClearHandler}>
              Clear
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

TransactionsFilter.propTypes = {
  onFilterSubmitHandler: PropTypes.func,
  isLoading: PropTypes.bool,
};

TransactionsFilter.defaultProps = {
  onFilterSubmitHandler: () => {},
  isLoading: false,
};

export default TransactionsFilter;
