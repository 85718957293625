import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { ErrorBoundary } from 'features/errorBoundaries';
import { mainRoutes } from 'features/main';
import { SetNavigation } from 'features/layout';
import { LoadingFlom } from 'features/components';
import { CheckRole } from 'features/hoc';
import {
  adminUsersRoutes,
  adminUsersRoutesRelative,
  breadcrumbLabels,
  breadcrumbLinks,
} from '../../routes';
import { adminUsersRoleRules } from '../../hooks';

//lazyImports
const PageNotFound = lazy(() => import('features/error-pages/fragments/pageNotFound/PageNotFound'));
const UsersList = lazy(() => import('../../users/fragments/usersList/UsersList'));
const UserDetails = lazy(() => import('../../users/fragments/userDetails/UserDetails'));
const CreateUser = lazy(() => import('../../createUser/fragments/createUser/CreateUser'));

function AdminUsers() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingFlom />}>
        <Routes>
          <Route
            path={adminUsersRoutesRelative.USER_DETAILS}
            element={
              <CheckRole {...adminUsersRoleRules.menu}>
                <UserDetails />
              </CheckRole>
            }
          />

          <Route
            path={adminUsersRoutesRelative.USERS}
            element={
              <CheckRole {...adminUsersRoleRules.menu}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.users}
                  breadcrumbsLabels={breadcrumbLabels.users}
                  siderOpenedKey={mainRoutes.ADMIN_USERS}
                  siderSelectedKey={adminUsersRoutes.USERS}
                >
                  <UsersList />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={adminUsersRoutesRelative.CREATE_USER}
            element={
              <CheckRole {...adminUsersRoleRules.menu}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.createUser}
                  breadcrumbsLabels={breadcrumbLabels.createUser}
                  siderOpenedKey={mainRoutes.ADMIN_USERS}
                  siderSelectedKey={adminUsersRoutes.CREATE_USER}
                >
                  <CreateUser />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route path="/" element={<Navigate to={adminUsersRoutes.USERS} />} />

          <Route path={adminUsersRoutesRelative[404]} element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default AdminUsers;
