import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import styles from './FulfillmentInfo.module.scss';

function FulfillmentInfo({ transaction }) {
  return (
    <div>
      <Typography.Title level={3}>Fulfillment info:</Typography.Title>
      <div className={styles.transferInfoGrid}>
        {transaction.fulfillment.APIRequest && (
          <>
            <Typography.Text
              className={[styles.itemTitle, styles.paymentResponseTitle].join(' ')}
              strong
            >
              API Request:
            </Typography.Text>
            <code className={styles.code}>
              <pre style={{ margin: 0, padding: 0 }}>
                {JSON.stringify(transaction.fulfillment.APIRequest, null, 2)}
              </pre>
            </code>
          </>
        )}

        {transaction.fulfillment.APIResponse && (
          <>
            <Typography.Text
              className={[styles.itemTitle, styles.paymentResponseTitle].join(' ')}
              strong
            >
              API Response:
            </Typography.Text>
            <code className={styles.code}>
              <pre style={{ margin: 0, padding: 0 }}>
                {JSON.stringify(transaction.fulfillment.APIResponse, null, 2)}
              </pre>
            </code>
          </>
        )}

        {transaction.fulfillment.callbackAPIResponse && (
          <>
            <Typography.Text
              className={[styles.itemTitle, styles.paymentResponseTitle].join(' ')}
              strong
            >
              callback API Response:
            </Typography.Text>
            <code className={styles.code}>
              <pre style={{ margin: 0, padding: 0 }}>
                {JSON.stringify(transaction.fulfillment.callbackAPIResponse, null, 2)}
              </pre>
            </code>
          </>
        )}

        {transaction.fulfillment.status && (
          <>
            <Typography.Text
              className={[styles.itemTitle, styles.paymentResponseTitle].join(' ')}
              strong
            >
              Status:
            </Typography.Text>
            <code className={styles.code}>
              <pre style={{ margin: 0, padding: 0 }}>
                {JSON.stringify(transaction.fulfillment.status, null, 2)}
              </pre>
            </code>
          </>
        )}
      </div>
    </div>
  );
}

FulfillmentInfo.propTypes = {
  transaction: PropTypes.object,
};

FulfillmentInfo.defaultProps = {
  transaction: {},
};

export default FulfillmentInfo;
