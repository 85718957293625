import { useSelector } from 'react-redux';
import { checkAll, roles } from '../helpers';

/**
 * @param {Object} objArgs args
 * @param {Number} objArgs.min minimum role in range, priority: less than include and exclude
 * @param {Number} objArgs.max maximum role in range, priority: less than include and exclude
 * @param {Array.<Number>} objArgs.include Array of included roles, priority: less than exclude
 * @param {Array.<Number>} objArgs.exclude Array od excluded roles, priority: top
 * @param {String} objArgs.type only flom
 * @return {Boolean} true if users role have access
 */

function useCheckRole(objArgs = {}) {
  const {
    min = roles.superAdmin.value,
    max = roles.superAdmin.value,
    include = [],
    exclude = [],
    type = 'flom',
  } = objArgs;

  const authorization = useSelector((state) => state.authorization);
  const role = authorization[type]?.role;

  if (role) {
    if (checkAll(role, min, max, include, exclude)) {
      return true;
    }
  }

  return false;
}

export default useCheckRole;
