import React from 'react';
import { Form } from 'antd';
import { PhoneNumberInput } from 'features/components';

function PhoneNumberFormItem() {
  return (
    <Form.Item label="Phone number" name="phoneNumber">
      <PhoneNumberInput placeholder="+2348130717898" />
    </Form.Item>
  );
}

export default PhoneNumberFormItem;
