import React from 'react';
import { Row, Col, Spin } from 'antd';
// import PropTypes from 'prop-types';

function LoadingSpinner(props) {
  return (
    <Row justify="center">
      <Col>
        <Spin size="large" />
      </Col>
    </Row>
  );
}

LoadingSpinner.propTypes = {};

LoadingSpinner.defaultProps = {};

export default LoadingSpinner;
