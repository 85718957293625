import React, { useState, useEffect, useRef, createRef } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './SegmentedInput.module.scss';

function isNumber(char) {
  return /^\d+$/.test(char);
}

function SegmentedInput({ cellNumber, onChange }) {
  const inputs = new Array(cellNumber).fill(null);
  const [numbers, setNumbers] = useState(new Array(cellNumber).fill(''));
  const refElements = useRef(inputs.map(() => createRef()));

  useEffect(() => {
    refElements.current[0].current.focus();
  }, []);

  const onInputChange = (newNumbers) => {
    onChange(newNumbers.join(''));
  };

  const segmentControl = (event, index) => {
    const char = event.key;

    if (char === 'Backspace') {
      const newNumbers = [...numbers];

      if (numbers[index] === '') {
        newNumbers[index - 1] = '';
        setNumbers(newNumbers);
        if (refElements.current[index - 1]) {
          refElements.current[index - 1].current.focus();
        }
        onInputChange(newNumbers);
        return;
      }
      newNumbers[index] = '';
      setNumbers(newNumbers);

      onInputChange(newNumbers);
      return;
    }

    if (char === 'Delete') {
      const newNumbers = [...numbers];
      newNumbers[index] = '';
      setNumbers(newNumbers);
      if (refElements.current[index + 1]) {
        refElements.current[index].current.focus();
      }

      onInputChange(newNumbers);
      return;
    }

    if (char === 'ArrowLeft') {
      if (refElements.current[index - 1]) {
        refElements.current[index - 1].current.focus();
        refElements.current[index - 1].current.select();
      }
      return;
    }

    if (char === 'ArrowRight') {
      if (refElements.current[index + 1]) {
        refElements.current[index + 1].current.focus();
        refElements.current[index + 1].current.select();
      }
      return;
    }
  };

  const onChangeHandler = (event, index) => {
    const char = event.target.value.replace(numbers[index], '');

    if (isNumber(char)) {
      const newNumbers = [...numbers];
      newNumbers[index] = char;
      setNumbers(newNumbers);
      if (refElements.current[index + 1]) {
        refElements.current[index + 1].current.focus();
      }

      onInputChange(newNumbers);
    }
  };

  return (
    <div className={styles.container}>
      {inputs.map((_, index) => (
        <Input
          className={styles.cell}
          key={index}
          ref={refElements.current[index]}
          value={numbers[index]}
          maxLength={2}
          onKeyDown={(e) => segmentControl(e, index)}
          onChange={(e) => {
            onChangeHandler(e, index);
          }}
          type="tel"
        />
      ))}
    </div>
  );
}

SegmentedInput.propTypes = {
  cellNumber: PropTypes.number,
  onChange: PropTypes.func,
};

SegmentedInput.defaultProps = {
  cellNumber: 6,
  onChange: () => {},
};

export default SegmentedInput;
