import React, { memo } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { status as statusType } from '../../const';

function ProductStatus({ status }) {
  const statusToText = (status) => {
    switch (status) {
      case statusType.pending.code:
        return <Tag color="yellow">{statusType.pending.name}</Tag>;
      case statusType.declined.code:
        return <Tag color="red">{statusType.declined.name}</Tag>;
      case statusType.approved.code:
        return <Tag color="green">{statusType.approved.name}</Tag>;
      case statusType.approvalNeeded.code:
        return <Tag color="orange">{statusType.approvalNeeded.name}</Tag>;
      default:
        return <Tag color="default">Unknown</Tag>;
    }
  };
  return statusToText(status);
}

ProductStatus.propTypes = {
  status: PropTypes.oneOf([1, 2, 3, 4]),
};

export default memo(ProductStatus);
