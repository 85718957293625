import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setBreadcrumbs,
  resetBreadcrumbs,
  addSiderOpenedKey,
  addSiderOpenedKeys,
  patchNavigation,
} from '../../navigationSlice';

function SetNavigation({
  children,
  breadcrumbsDisabled,
  breadcrumbsLabels,
  breadcrumbsLinks,
  breadcrumbsStates,
  siderOpenedKey,
  siderSelectedKey,
  siderOpenedKeys,
}) {
  const dispatch = useDispatch();
  const { siderOpenedKeys: siderOpenedKeysRedux, siderSelectedKey: siderSelectedKeyRedux } =
    useSelector((state) => state.navigation);

  useEffect(() => {
    dispatch(
      setBreadcrumbs({
        breadcrumbsDisabled,
        breadcrumbsLabels,
        breadcrumbsLinks,
        breadcrumbsStates,
      })
    );
  }, [dispatch, breadcrumbsDisabled, breadcrumbsLabels, breadcrumbsLinks, breadcrumbsStates]);

  useEffect(() => {
    if (siderSelectedKey !== siderSelectedKeyRedux) {
      dispatch(patchNavigation({ siderSelectedKey: siderSelectedKey }));
    }
    if (!siderOpenedKeysRedux.includes(siderOpenedKey)) {
      dispatch(addSiderOpenedKey(siderOpenedKey));
    }
  }, [dispatch, siderOpenedKey, siderSelectedKey]);

  useEffect(() => {
    if (siderOpenedKeys) {
      dispatch(addSiderOpenedKeys(siderOpenedKeys));
    }
  }, [dispatch, siderOpenedKeys]);

  useEffect(() => {
    return () => {
      dispatch(resetBreadcrumbs());
    };
  }, [dispatch]);

  return children ? children : null;
}

SetNavigation.propTypes = {
  breadcrumbsDisabled: PropTypes.bool,
  breadcrumbsLabels: PropTypes.arrayOf(PropTypes.string),
  breadcrumbsLinks: PropTypes.arrayOf(PropTypes.string),
  breadcrumbsStates: PropTypes.arrayOf(PropTypes.object),
  siderOpenedKey: PropTypes.string,
  siderSelectedKey: PropTypes.string,
  siderOpenedKeys: PropTypes.arrayOf(PropTypes.string),
};

SetNavigation.defaultProps = {
  breadcrumbsDisabled: false,
  breadcrumbsLabels: [],
  breadcrumbsLinks: [],
  breadcrumbsStates: [],
  siderOpenedKey: '',
  siderSelectedKey: '',
};

export default SetNavigation;
