import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ContactAdministrator } from 'features/error-pages';
import { checkAll, roles } from '../../helpers';

function CheckRole({
  type,
  include,
  exclude,
  min,
  max,
  children,
  alternativePage,
  defaultAlternative,
}) {
  const authorization = useSelector((state) => state.authorization);
  const role = authorization[type]?.role;

  if (role) {
    if (checkAll(role, min, max, include, exclude)) {
      return children || null;
    }
  }

  if (alternativePage) {
    return alternativePage;
  }

  if (defaultAlternative) {
    return <ContactAdministrator />;
  }

  return null;
}

CheckRole.propTypes = {
  type: PropTypes.oneOf(['flom']),
  min: PropTypes.number,
  max: PropTypes.number,
  include: PropTypes.arrayOf(PropTypes.number),
  exclude: PropTypes.arrayOf(PropTypes.number),
  alternativePage: PropTypes.element,
  defaultAlternative: PropTypes.bool,
};

CheckRole.defaultProps = {
  type: 'flom',
  min: roles.superAdmin.value,
  max: roles.superAdmin.value,
  include: [],
  exclude: [],
  defaultAlternative: true,
};

export default CheckRole;
