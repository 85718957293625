import { useLocation } from 'react-router-dom';

function useParametersFromLocationSearch(props) {
  const location = useLocation();

  const queryFromLocation = (location) => {
    const searchParams = new URLSearchParams(location.search);
    let queryParams = {};
    searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });
    return queryParams;
  };

  return queryFromLocation(location);
}

export { useParametersFromLocationSearch };
