import React, { useState } from 'react';
import RenderSmoothImage from 'render-smooth-image-react';
import PropTypes from 'prop-types';
import { productTypes } from '../../const';
import styles from './ProductImage.module.scss';
import VideoIcon from '../../assets/video_icon.svg';
import ExpoIcon from '../../assets/expo_icon.svg';
import PodcastIcon from '../../assets/podcast_icon.svg';
import TextStoryIcon from '../../assets/text_story_icon.svg';
import ProductIcon from '../../assets/product_icon.svg';

function ProductImage({ src, productType }) {
  const [error, setError] = useState(false);

  const errorHandler = () => {
    if (!error) {
      setError(true);
    }
  };

  const createImage = (src, productType) => {
    if (error) {
      switch (productType) {
        case productTypes.video.value:
          return <img className={styles.image} src={VideoIcon} alt="video" />;
        case productTypes.expo.value:
          return <img className={styles.image} src={ExpoIcon} alt="expo" />;
        case productTypes.podcast.value:
          return <img className={styles.image} src={PodcastIcon} alt="podcast" />;
        case productTypes.textStory.value:
          return <img className={styles.image} src={TextStoryIcon} alt="text story" />;
        case productTypes.product.value:
          return <img className={styles.image} src={ProductIcon} alt="product" />;
        default:
          return <RenderSmoothImage src={src} />;
      }
    }
    return <RenderSmoothImage src={src} onError={errorHandler} />;
  };

  return createImage(src, productType);
}

ProductImage.propTypes = {
  src: PropTypes.string,
  productType: PropTypes.oneOf([1, 2, 3, 4, 5]),
};

ProductImage.defaultProps = {
  src: null,
  productType: null,
};

export default ProductImage;
