import React from 'react';
import { Input } from 'antd';

export function UsernameInput({ ...otherProps }) {
  return (
    <Input
      name="username"
      autoComplete="username"
      placeholder="Username"
      autoCapitalize="off"
      {...otherProps}
    />
  );
}

export function PasswordCurrentInput({ ...otherProps }) {
  return (
    <Input.Password
      autoComplete="current-password"
      name="password"
      placeholder="Password"
      {...otherProps}
    />
  );
}

export function PasswordNewInput({ ...otherProps }) {
  return (
    <Input.Password
      autoComplete="new-password"
      name="password"
      placeholder="Password"
      {...otherProps}
    />
  );
}

export function PasswordConfirmInput({ ...otherProps }) {
  return (
    <Input.Password
      autoComplete="new-password"
      name="confirmPassword"
      placeholder="Confirm password"
      {...otherProps}
    />
  );
}

export function EmailInput({ ...otherProps }) {
  return (
    <Input autoComplete="email" name="email" type="text" placeholder="Email" {...otherProps} />
  );
}

export function FirstNameInput({ ...otherProps }) {
  return <Input autoComplete="given-name" name="fname" placeholder="First name" {...otherProps} />;
}

export function LastNameInput({ ...otherProps }) {
  return <Input autoComplete="family-name" name="lname" placeholder="Last name" {...otherProps} />;
}

export function PhoneNumberInput({ ...otherProps }) {
  return (
    <Input
      autoComplete="tel"
      name="phoneNumber"
      type="tel"
      placeholder="Phone number"
      {...otherProps}
    />
  );
}

export function AddressInput({ ...otherProps }) {
  return <Input autoComplete="address" name="address" placeholder="Address" {...otherProps} />;
}
