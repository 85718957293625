export const status = {
  pending: { name: 'Pending', code: 1 },
  declined: { name: 'Declined', code: 2 },
  approved: { name: 'Approved', code: 3 },
  approvalNeeded: { name: 'Approval needed', code: 4 },
};

export const productTypes = {
  product: { value: 5, name: 'Product' },
  video: { value: 1, name: 'Video' },
  podcast: { value: 3, name: 'Podcast' },
  textStory: { value: 4, name: 'Text story' },
  expo: { value: 2, name: 'Expo' },
};
